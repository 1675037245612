import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  " - 카톡으로 사전예약부터 상담까지, ‘쉽고’ ‘싸고’ ‘빠르게’ 아이폰 12 유저되기",
  " - 이커머스 익숙한 2030 소비자 고려, 다양한 카카오 서비스 제휴 혜택까지",
]

const content = () => (
  <div>
    <style>
      {
        "\
        .pressDetailPage .pressDetailBody .titleArea .title {\
          text-indent: -10px;\
          padding-left: 10px;\
        }\
        .pressDetailPage .pressDetailBody .titleArea .suibTitle {\
          padding-left: 10px;\
        }\
        .pressDetailPage .pressDetailBody .contentArea .descImage {\
          display: table;\
          width: 100%;\
        }\
        .pressDetailPage .pressDetailBody .contentArea .descImage div {\
          display: table-cell;\
          vertical-align: middle;\
        }\
        "
      }
    </style>
    <br />
    <div className="descImage">
      <div>
        <img src="/images/press/5/zVcRXpmJ.jpg" alt="Figure1" />
      </div>
      <div style={{ width: 5 + "px" }}></div>
      <div>
        <img src="/images/press/5/TRjtaFKk.jpg" alt="Figure2" />
      </div>
    </div>
    <br />
    <div className="descImage">
      <div>
        <img src="/images/press/5/evaJCAcc.jpg" alt="Figure3" />
      </div>
      <div style={{ width: 5 + "px" }}></div>
      <div>
        <img src="/images/press/5/zDWMrWbY.jpg" alt="Figure4" />
      </div>
    </div>
    <br />
    <p>
      <br />
      ㅣ 스테이지파이브(공동대표 서상원, 이제욱)은 23일부터 ‘핀다이렉트샵’을 통해 아이폰 12 사전예약을 개시한다고 밝혔다.
      ‘핀다이렉트샵’은 카카오톡 안에서 만나볼 수 있는 통신구매 플랫폼이다. 스테이지파이브는 해당 플랫폼을 통해 단순 단말 또는
      요금제 판매를 넘어, 통신 기반한 라이프스타일 베네핏을 제공하는데에 초점을 맞추고 있다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 핀다이렉트샵은 이커머스에 익숙한 2030세대를 위한 UX설계로 시장에서 좋은 반응을 얻고 있다. 이곳저곳 흩어져 있던 포인트를
      한 번에 모아 선할인이 가능한 점이나 카카오페이 본인인증 으로 가입절차가 간편해졌다는 점, 배송이나 상담 등 구매 이후 절차도
      앱 이탈 없이 카카오톡으로 해결 가능한 점 등이 그것이다. 통신비 결제 또한 페이로 가능하다. 이미 생활 깊숙이 들어와 있는 페이
      서비스에 통신 서비스가 추가되어 편리함을 배가시킨 셈이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 결국 ‘핀다이렉트샵’은 기존 통신가입 절차였던 가입신청서 작성, 할인혜택에 대한 구두설명과 조건별로 상이한 할인적용 요금을
      고객이 직접 계산해야 하는 한계, 신분증스캔 및 전송에 따른 보안불안감 등을 한번에 해결한 플랫폼이라 할 수 있을 것이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 기능적인 편리함 외에도 핀다이렉트샵은 카카오톡 베이스의 앱인앱 플랫폼이라는 측면에서, 고객들에게 ‘카카오 라이프’를 누릴
      수 있는 각종 제휴혜택을 발굴 및 제공해 왔다. 이번 아이폰12 사전예약을 맞이하여 구매고객에게 제공되는 제휴혜택은 한층 더
      강화되었다. 사전예약 주문 시 혜택을 받을 수 있는 쿠폰과 카카오페이지 3,000캐시를 선착순 1200명에게 지급하고 있다. 또한 특별
      제작한 카카오 웰컴키트를 함께 증정하여 카카오만의 감성을 담은 통신라이프를 더욱 풍성히 할 수 있을 전망이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 특히 얼리버드 예약 공유이벤트를 통하면 애플워치 SE, 멜론 무제한 6개월 이용권, 카카오페이지 캐시를 지급하는 1+1 이벤트도
      진행중이다. 스테이지파이브는 향후 핀다이렉트샵 고객들이 보다 더 풍성한 카카오 라이프를 누릴 수 있는 공동체 내 제휴 혜택을
      점차 늘려갈 예정이다. 가깝게는 오는 23일부터 핀다이렉트샵 고객들만을 위한 특별한 깜짝 혜택을 추가 오픈할 예정이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 누구보다 먼저 아이폰12 사전예약 알림을 받아볼 수 있는 얼리버드 신청은 핀다이렉트샵 카카오톡 채널을 통하면 가능하다.
      카카오톡 채팅하기 창에서 ‘핀다이렉트샵’을 검색해 채널을 추가하면 더욱 다양한 정보를 빠르고 쉽게 받아볼 수 있다.
      <br />
    </p>
  </div>
)

const press5 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2020년 10월 21일 즉시 배포 가능."
      pageInfo="(사진자료 메일 첨부함)"
      title="| “아이폰12 쉽싸빠 할 사람?” <br />2030 라이프스타일 맞춤형 통신구매 플랫폼 ‘핀다이렉트샵’, <br />아이폰12 사전예약 실시"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press5
